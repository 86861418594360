<template>
    <div class="about row">
        <vxe-grid ref="xGrid" v-bind="gridOptions">
            <template #option_btn="{ row }">
                <div class="btn-group" role="group" aria-label="Basic mixed styles example">
                    <button type="button" @click="edit(row)" class="btn btn-success">
                        <i class="fa-solid fa-pen-to-square"></i>
                    </button>
                    <button type="button" class="btn btn-warning d-none">Middle</button>
                    <button type="button" @click="removeOne(row)" class="btn btn-danger">
                        <i class="fa-solid fa-trash-can"></i>
                    </button>
                </div>
            </template>
            <template #status_switch="{ row }">
                <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" role="switch" :data-id="row.id" :checked="row.status"
                        @change="change($event, row)" />
                </div>
            </template>
            <template #toolbar_buttons>
                <vxe-button @click="add" status="success">新增</vxe-button>
            </template>
        </vxe-grid>

        <vxe-modal v-model="showEdit" :title="selectRow ? '编辑&保存' : '新增&保存'" width="645" min-width="300" min-height="300"
            :loading="loadding" :resize="true" destroy-on-close @close="reset">
            <template #default>
                <vxe-form :data="formData" :rules="formRules" title-align="right" @submit="submitEvent">
                    <template>

                        <vxe-form-item title="关键信息" title-align="left" :span="24"
                            :title-prefix="{ message: '订单号必填', icon: 'vxe-icon-comment' }"></vxe-form-item>

                        <vxe-form-item field="oid" title="订单号" :item-render="{}">
                            <template #default="{ data }">
                                <vxe-input v-model="data.oid" placeholder="请输入订单号"></vxe-input>
                            </template>
                        </vxe-form-item>

                        <vxe-form-item field="custom_name" title="客户" :item-render="{}">
                            <template #default="{ data }">
                                <vxe-input v-model="data.custom_name" placeholder="请输入客户名"></vxe-input>
                            </template>
                        </vxe-form-item>
                        <vxe-form-item field="worker_name" title="维修员" :item-render="{}">
                            <template #default="{ data }">
                                <vxe-input v-model="data.worker_name" placeholder="请输入维修员"></vxe-input>
                            </template>
                        </vxe-form-item>
                        <vxe-form-item field="store_name" title="门店" :item-render="{}">
                            <template #default="{ data }">
                                <vxe-input v-model="data.store_name" placeholder="请输入门店"></vxe-input>
                            </template>
                        </vxe-form-item>
                        <vxe-form-item field="repository" title="仓库" :item-render="{}">
                            <template #default="{ data }">
                                <vxe-input v-model="data.repository" placeholder="请输入仓库"></vxe-input>
                            </template>
                        </vxe-form-item>
                        <vxe-form-item field="custom_phone" title="联系电话" :item-render="{}">
                            <template #default="{ data }">
                                <vxe-input v-model="data.custom_phone" placeholder="联系电话"></vxe-input>
                            </template>
                        </vxe-form-item>


                        <vxe-form-item field="product" title="维修项目" :item-render="{}">
                            <template #default="{ data }">
                                <vxe-input v-model="data.product" placeholder="维修项目"></vxe-input>
                            </template>
                        </vxe-form-item>
                        <vxe-form-item field="repair_time" title="送修日期" :item-render="{}">
                            <template #default="{ data }">
                                <vxe-input v-model="data.repair_time" placeholder="选择日期" type="date"></vxe-input>
                            </template>
                        </vxe-form-item>

                        <vxe-form-item field="predict_time" title="预计完成时间" :item-render="{}">
                            <template #default="{ data }">
                                <vxe-input v-model="data.predict_time" placeholder="选择日期" type="date"></vxe-input>
                            </template>
                        </vxe-form-item>
                        <vxe-form-item field="complete_time" title="完成时间" :item-render="{}">
                            <template #default="{ data }">
                                <vxe-input v-model="data.complete_time" placeholder="选择日期" type="date"></vxe-input>
                            </template>
                        </vxe-form-item>
                        <vxe-form-item field="repair_state" title="维修进度" :item-render="{}">
                            <template #default="{ data }">
                                <vxe-input v-model="data.repair_state" placeholder="维修进度"></vxe-input>
                            </template>
                        </vxe-form-item>
                        <vxe-form-item field="price" title="总额" :item-render="{}">
                            <template #default="{ data }">
                                <vxe-input v-model="data.price" placeholder="请输入总额" type="float"></vxe-input>
                            </template>
                        </vxe-form-item>

                        <vxe-form-item title="额外信息" title-align="left" :title-width="200" :span="24" :title-prefix="{
                            message: '可选的信息',
                            icon: 'vxe-icon-info-circle-fill',
                        }"></vxe-form-item>
                        <vxe-form-item field="model" title="维修机型" :item-render="{}">
                            <template #default="{ data }">
                                <vxe-input v-model="data.model" placeholder="维修机型"></vxe-input>
                            </template>
                        </vxe-form-item>
                        <vxe-form-item field="peijian" title="配件" :item-render="{}">
                            <template #default="{ data }">
                                <vxe-input v-model="data.peijian" placeholder="配件"></vxe-input>
                            </template>
                        </vxe-form-item>
                        <vxe-form-item field="number" title="序列号" :item-render="{}">
                            <template #default="{ data }">
                                <vxe-input v-model="data.number" placeholder="序列号"></vxe-input>
                            </template>
                        </vxe-form-item>
                        <vxe-form-item field="model_desc" title="机型描述" :item-render="{}">
                            <template #default="{ data }">
                                <vxe-input v-model="data.model_desc" placeholder="机型描述"></vxe-input>
                            </template>
                        </vxe-form-item>
                        <vxe-form-item field="time_fee" title="工时费" :item-render="{}">
                            <template #default="{ data }">
                                <vxe-input v-model="data.time_fee" placeholder="请输入工时费" type="float"></vxe-input>
                            </template>
                        </vxe-form-item>
                        <vxe-form-item field="peijian_fee" title="零件费" :item-render="{}">
                            <template #default="{ data }">
                                <vxe-input v-model="data.peijian_fee" placeholder="请输入零件费" type="float"></vxe-input>
                            </template>
                        </vxe-form-item>
                        <vxe-form-item field="zone_fee" title="抹零费用" :item-render="{}">
                            <template #default="{ data }">
                                <vxe-input v-model="data.zone_fee" placeholder="请输入抹零费用" type="float"></vxe-input>
                            </template>
                        </vxe-form-item>
                        <vxe-form-item field="debt_fee" title="欠款金额" :item-render="{}">
                            <template #default="{ data }">
                                <vxe-input v-model="data.debt_fee" placeholder="请输入欠款金额" type="float"></vxe-input>
                            </template>
                        </vxe-form-item>
                        <vxe-form-item field="desc" title="备注" :span="24" :item-render="{}">
                            <template #default="{ data }">
                                <vxe-textarea v-model="data.desc" :autosize="{ minRows: 2, maxRows: 4 }">
                                </vxe-textarea>
                            </template>
                        </vxe-form-item>

                        <vxe-form-item align="center" title-align="left" :span="24">
                            <template #default>
                                <vxe-button type="submit">提交</vxe-button>
                                <vxe-button @click="reset">重置</vxe-button>
                            </template>
                        </vxe-form-item>
                    </template>




                </vxe-form>
            </template>
        </vxe-modal>
    </div>
</template>
<script>
import {
    ordersAll,
    getKFOrder,
    saveKFOrder,
    getAllWorker,
    getAllSotre,
    updateKFOrder,
    repositoryDel,
    kfOrderImport,
    updateKFOrderStatus
} from "@/requests/api";
import { VXETable } from "vxe-table";
import { ref } from 'vue'
const xGrid = ref()

const orderObj = [
    { label: '到店订单', value: 0 },
    { label: '上门订单', value: 1 },
    { label: '邮寄订单', value: 2 }
]

const statusObj = [
    { label: '未完成', value: 0 },
    { label: '已完成', value: 1 },
    { label: '全部', value: -1 },
]
export default {
    name: "",
    mounted() {
        console.log(this.$refs.xGrid);
    },
    created() {
        getAllSotre({ type: 2 }).then((res) => {
            this.stores = res
        })
        VXETable.modal.settop = 80;
    },
    data() {
        return {
            stores: [],
            workers: [],
            selectRow: true,
            orderTypesAll: orderObj,
            ordertypes: [{ label: '上门订单', value: 1 },
            { label: '邮寄订单', value: 2 }],
            showEdit: false,
            loadding: false,
            formData: {
                oid: '',
                uid: '',
                wid: "",
                custom_name: "",
                store_name: "",
                worker_name: "",
                desc: "",
                debt_fee: 0,
                zone_fee: 0,
                peijian_fee: 0,
                price: 0,
                peijian: "",
                time_fee: 0,
                product: "",
                model_desc: "",
                model: "",
                repository: "",
                repair_time: "",
                predict_time: "",
                complete_time: "",
                custom_phone: "",
                number: "",
                repair_state: ""

            },
            typelist: ["get", "post", "put", "delete"],
            formRules: {
                name: [
                    { required: true, message: "请输入名称" },
                    { min: 2, max: 6, message: "长度在 2 到 6 个字符" },
                ],
            },
            gridOptions: {
                border: true,
                showHeaderOverflow: true,
                showOverflow: true,
                keepSource: true,
                id: "menu_list_table",
                height: 800,
                rowConfig: {
                    keyField: "id",
                    isHover: true,
                },
                columnConfig: {
                    resizable: true,
                },
                customConfig: {
                    storage: true,
                    checkMethod({ column }) {
                        if (["id"].includes(column.field)) {
                            return false;
                        }
                        return true;
                    },
                },
                printConfig: {
                    // columns: [
                    //     { field: "name" },
                    //     { field: "path" },
                    //     { field: "link" },
                    //     { field: "pid" },
                    //     { field: "icon" },
                    //     { field: "status" },
                    //     { field: "order" },
                    //     { field: "desc" },
                    // ],
                },
                sortConfig: {
                    trigger: "cell",
                    remote: true,
                },
                filterConfig: {
                    remote: true,
                },
                pagerConfig: {
                    pageSize: 10,
                    pageSizes: [5, 10, 15, 20, 50, 100, 200, 500, 1000],
                },
                formConfig: {
                    titleWidth: 100,
                    titleAlign: "center",
                    items: [
                        {
                            field: "oid",
                            title: "订单号",
                            titlePrefix: {
                                message: "订单号不是id",
                                icon: "vxe-icon-question-circle-fill",
                            },
                            itemRender: {
                                name: "$input",
                                props: { placeholder: "请输入订单号" },
                            },
                        },
                        {
                            field: "custom_name",
                            title: "用户名",
                            itemRender: {
                                name: "$input",
                                props: { placeholder: "请输入用户名" },
                            },
                        },
                        {
                            field: "store_name",
                            title: "店铺名",
                            itemRender: {
                                name: "$input",
                                props: { placeholder: "请输入店铺名" },
                            },
                        },
                        {
                            field: "worker_name",
                            title: "技师名",
                            itemRender: {
                                name: "$input",
                                props: { placeholder: "请输入技师名" },
                            },
                        },
                        {
                            field: "start_time",
                            title: "开始日期",
                            itemRender: {
                                name: "$input",
                                props: { placeholder: "请输入开始日期", type: 'datetime' },
                            },
                        },
                        {
                            field: "end_time",
                            title: "结束日期",
                            itemRender: {
                                name: "$input",
                                props: { placeholder: "请输入结束日期", type: 'datetime' },
                            },
                        },
                        {
                            field: "status",
                            title: "订单状态",
                            itemRender: {
                                name: "$select",
                                options: statusObj,
                                defaultValue: -1
                            },
                        },
                        {
                            align: "center",
                            // collapseNode: true,
                            itemRender: {
                                name: "$buttons",
                                children: [
                                    {
                                        props: {
                                            type: "submit",
                                            content: "搜索",
                                            status: "primary",
                                        },
                                    },
                                    { props: { type: "reset", content: "清空" } },
                                ],
                            },
                        },
                    ],
                },
                toolbarConfig: {
                    slots: {
                        buttons: "toolbar_buttons",
                    },
                    refresh: true,
                    import: true,
                    export: false,
                    print: true,
                    zoom: false,
                    custom: true,
                },
                proxyConfig: {
                    seq: true, // 启用动态序号代理，每一页的序号会根据当前页数变化
                    sort: true, // 启用排序代理，当点击排序时会自动触发 query 行为
                    filter: true, // 启用筛选代理，当点击筛选时会自动触发 query 行为
                    form: true, // 启用表单代理，当点击表单提交按钮时会自动触发 reload 行为
                    // 对应响应结果 { result: [], page: { total: 100 } }
                    props: {
                        result: "data", // 配置响应结果列表字段
                        total: "total", // 配置响应结果总页数字段
                    },
                    // 只接收Promise，具体实现自由发挥
                    ajax: {
                        // 当点击工具栏查询按钮或者手动提交指令 query或reload 时会被触发
                        query: ({ page, sorts, filters, form }) => {
                            const queryParams = Object.assign({}, form);
                            // 处理排序条件
                            const firstSort = sorts[0];
                            if (firstSort) {
                                queryParams.sort = firstSort.field;
                                queryParams.order = firstSort.order;
                            }
                            // 处理筛选条件
                            filters.forEach(({ field, values }) => {
                                queryParams[field] = values.join(",");
                            });
                            queryParams.page = page.currentPage;
                            queryParams.limit = page.pageSize;
                            return getKFOrder(queryParams);
                        },
                        queryAll: ({ filters, form }) => {
                            const queryParam = Object.assign({}, form);
                            filters.forEach(({ field, values }) => {
                                queryParam[field] = values.join(",");
                            });
                            return ordersAll(queryParam);
                        },
                        // 当点击工具栏删除按钮或者手动提交指令 delete 时会被触发
                        delete: ({ body }) => {
                            console.log(body);

                            return;
                        },
                        // 当点击工具栏保存按钮或者手动提交指令 save 时会被触发
                        save: ({ body }) => {
                            console.log(body);
                            return;
                        },
                        edit: ({ id }) => {
                            console.log(id);
                        },
                    },
                },
                columns: [
                    { type: "checkbox", title: "ID", width: 86 },
                    {
                        field: "oid",
                        sortable: false,
                        title: "订单号",
                    },
                    {
                        field: "custom_name",
                        title: "客户",
                        sortable: false,
                    },
                    {
                        field: "worker_name",
                        title: "维修员",
                        sortable: false,
                    },
                    {
                        field: "store_name",
                        title: "门店",
                        sortable: false,
                    },
                    {
                        field: "repository",
                        title: "仓库",
                        sortable: false,
                    },
                    {
                        field: "custom_phone",
                        title: "联系电话",
                        sortable: false
                    },
                    {
                        field: "model",
                        title: "维修机型",
                        sortable: false,
                        visible: false,
                    },
                    {
                        field: "product",
                        title: "维修项目",
                        sortable: false
                    },
                    {
                        field: "repair_time",
                        title: "送修时间",
                        sortable: false
                    },
                    {
                        field: "predict_time",
                        title: "预计完成时间",
                        sortable: false
                    },
                    {
                        field: "complete_time",
                        title: "完成时间",
                        sortable: false
                    },
                    {
                        field: "repair_state",
                        title: "维修进度",
                        sortable: false
                    },
                    {
                        field: "price",
                        title: "总额",
                        sortable: false
                    },
                    {
                        field: "peijian",
                        title: "配件",
                        sortable: false,
                        visible: false,

                    },
                    {
                        field: "number",
                        title: "序列号",
                        sortable: false,
                        visible: false,

                    },
                    {
                        field: "model_desc",
                        title: "机型描述",
                        sortable: false,
                        visible: false,

                    },
                    {
                        field: "time_fee",
                        title: "工时费",
                        sortable: false,
                        visible: false,

                    },
                    {
                        field: "peijian_fee",
                        title: "零件费",
                        sortable: false,
                        visible: false,

                    },
                    {
                        field: "zone_fee",
                        title: "抹零金额",
                        sortable: false,
                        visible: false,

                    },
                    {
                        field: "debt_fee",
                        title: "欠款金额",
                        sortable: false,
                        visible: false,

                    },
                    {
                        field: "desc",
                        title: "描述",
                        sortable: false,
                        visible: false,

                    },
                    {
                        field: "create_time",
                        title: "创建时间",
                        sortable: false,
                        visible: false,
                    },
                    {
                        field: "update_time",
                        title: "更新时间",
                        sortable: false,
                        visible: false,

                    },
                    {
                        field: "status",
                        title: "订单状态",
                        sortable: false,
                        slots: {
                            default: 'status_switch'
                        },
                    },
                    {
                        field: "id",
                        title: "操作",
                        type: "html",
                        visible: false,
                        slots: {
                            default: "option_btn",
                        },
                    },

                ],
                importConfig: {
                    remote: true,
                    types: ["xlsx", "xls"],
                    modes: ['insert'],
                    // 自定义服务端导入
                    importMethod({ file, options }) {
                        console.log(options)
                        console.log(xGrid)
                        let formBody = new FormData();
                        console.log(formBody)
                        formBody.append("file", file);
                        console.log(file)
                        console.log(formBody)
                        return kfOrderImport(formBody).then((res) => {
                            if (!res.num) {
                                VXETable.modal.message({
                                    content: "导入失败，请检查数据是否正确！",
                                    status: "error",
                                    top: 80,
                                });
                            } else {
                                VXETable.modal.message({
                                    content: `成功导入 ${res.num} 条记录！`,
                                    status: "success",
                                    top: 80,
                                });
                                // 导入完成，刷新表格
                                this.$refs.grid.commitProxy("query");
                            }


                        })

                    },
                },
                exportConfig: {
                    // remote: true,
                    // types: ["xls"],
                    // modes: ["current", "selected", "all"],
                    // 自定义服务端导出
                    // exportMethod({ options }) {
                    //     const $grid = xGrid.value;
                    //     const proxyInfo = $grid.getProxyInfo();
                    //     // 传给服务端的参数
                    //     const body = {
                    //         filename: options.filename,
                    //         sheetName: options.sheetName,
                    //         isHeader: options.isHeader,
                    //         original: options.original,
                    //         mode: options.mode,
                    //         pager: proxyInfo ? proxyInfo.pager : null,
                    //         ids:
                    //             options.mode === "selected"
                    //                 ? options.data.map((item) => item.id)
                    //                 : [],
                    //         fields: options.columns.map((column) => {
                    //             return {
                    //                 field: column.field,
                    //                 title: column.title,
                    //             };
                    //         }),
                    //     };
                    //     // 开始服务端导出
                    //     return fetch({ body })
                    //         .then((response) => response.json())
                    //         .then((data) => {
                    //             if (data.id) {
                    //                 VXETable.modal.message({
                    //                     content: "导出成功，开始下载",
                    //                     status: "success",
                    //                 });
                    //                 // 读取路径，请求文件
                    //                 fetch({}).then((response) => {
                    //                     response.blob().then((blob) => {
                    //                         // 开始下载
                    //                         VXETable.saveFile({
                    //                             filename: "导出数据",
                    //                             type: "xlsx",
                    //                             content: blob,
                    //                         });
                    //                     });
                    //                 });
                    //             }
                    //         })
                    //         .catch(() => {
                    //             VXETable.modal.message({
                    //                 content: "导出失败！",
                    //                 status: "error",
                    //             });
                    //         });
                    // },
                },
                checkboxConfig: {
                    labelField: "id",
                    reserve: true,
                    highlight: true,
                    range: true,
                },
            },
        };
    },
    methods: {

        changeStore(e) {
            if (e.value == "") {
                return
            }
            getAllWorker({ type: this.formData.type, sid: e.value }).then((res) => {
                this.workers = res
            })
        },
        add() {
            this.selectRow = false;
            this.showEdit = true;
        },
        edit(row) {
            this.selectRow = true;
            this.formData = row;
            this.showEdit = true;
            delete this.formData.create_time;
            delete this.formData.update_time;
        },
        removeOne(row) {
            VXETable.modal
                .confirm({
                    content: "删除后无法恢复！确定要删除？",
                })
                .then((res) => {
                    if (res == "confirm") {
                        this.notifyDelete([row.id]);
                        this.$refs.xGrid.remove(row);
                    } else {
                        VXETable.modal.message({
                            status: "info",
                            content: "操作取消",
                            top: 80,
                        });
                    }
                });
        },
        removeMany() {
            if (this.$refs.xGrid.getCheckboxRecords().length == 0) {
                return
            }
            VXETable.modal
                .confirm({
                    content: "删除后无法恢复！确定要删除多条记录？",
                })
                .then((res) => {
                    if (res == "confirm") {
                        this.notifyDelete(
                            this.$refs.xGrid.getCheckboxRecords().map((x) => {
                                return x.id;
                            })
                        );
                        this.$refs.xGrid.removeCheckboxRow();
                    } else {
                        VXETable.modal.message({
                            status: "info",
                            content: "操作取消",
                            top: 80,
                        });
                    }
                });
        },
        notifyDelete(ids) {
            repositoryDel({
                id: ids,
            }).then(() => {
                this.$refs.xGrid.commitProxy('query')
                VXETable.modal.message({
                    status: "success",
                    content: "操作成功！",
                    top: 80,
                });
            });
        },
        change(e, row) {
            console.log(e, row);
            updateKFOrderStatus({
                id: row.id,
            })
                .then(() => {
                    VXETable.modal.message({
                        status: "success",
                        content: "操作成功！",
                        top: 80,
                    });
                })
                .catch(() => {
                    VXETable.modal.message({
                        status: "error",
                        content: "操作失败！",
                        top: 80,
                    });
                });
        },
        reset() {
            var default_Data = {
                oid: '',
                uid: '',
                wid: "",
                custom_name: "",
                store_name: "",
                worker_name: "",
                desc: "",
                debt_fee: 0,
                zone_fee: 0,
                peijian_fee: 0,
                price: 0,
                peijian: "",
                time_fee: 0,
                product: "",
                model_desc: "",
                model: "",
                repository: "",
                repair_time: "",
                predict_time: "",
                complete_time: "",
                custom_phone: "",
                number: "",
                repair_state: ""
            };
            this.formData = default_Data;
        },
        submitEvent() {
            if (this.selectRow) {
                updateKFOrder(this.formData).then(() => {
                    this.$refs.xGrid.reloadRow(this.formData);
                    this.showEdit = false;
                    VXETable.modal.message({
                        content: `更新成功`,
                        status: "success",
                    });
                    this.$refs.xGrid.commitProxy('query')
                });
            } else {
                saveKFOrder(this.formData)
                    .then((res) => {
                        console.log(res);
                        this.showEdit = false;
                        VXETable.modal.message({
                            content: `添加成功`,
                            status: "success",
                        });

                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
            this.reset();
        },
        visibleMethod() { },
    },
};
</script>
<style>
/* .vxe-table--header {
          background-color: green;
          color: #fff;
        } */
</style>
  